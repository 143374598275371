.sidebar {
  border-right: 1px solid #BFBFBF;
  .ant-menu {
    background: #F7F7FC;
  }
  .submenu-item {
    padding-left: 48px !important;
  }
  .ant-menu-item, .ant-menu-submenu-title {
    color: rgba(29,29,29,0.65);
    a:hover {
      color: black;
    }
  }
  .ant-menu-item:hover {
    background-color: transparent !important;
    color: black;
    a {
      color: black;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    a {
      color: black;
      font-weight: 500;
    }
  }
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
    color: black;
    font-weight: 500;
  }
  .ant-menu-item-selected:after {
    display: none;
  }
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .show-on-small-screen {
    display: none
  }
}
@media (max-width: 768px) {
  .sidebar .show-on-small-screen {
    display: block;
  }
  .sidebar-open {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
  }
}
footer {
  position: sticky;
  top: 100%;
  padding: 0;
  margin-bottom: -20px;
  background: #f7f7fc;

  a {
    color: #808080;
    margin-left: 15px;
  }

  span {
    color: #aaa;
  }
  .footer-content {
    display: flex;
  }
  .footer-left, .footer-right {
    flex: 1
  }
  .footer-right {
    text-align: right;
  }

  .footer-links {
    margin-top: -5px;
    vertical-align: sub;
  }

  .footer-letx {
    display: inline-block;
    margin-left: auto;
    margin-right: 0;
    font-family: Orkney,Quicksand,Arial;
    margin-top: -5px;
    vertical-align: sub;
  }
}
html, body {
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
}
.content-body {
  height: 100%;
}
.bold {
  font-weight: bold;
}
a {
  text-decoration: none !important;
}
.uploader {
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  .anticon-eye-o {
    display: none;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
.dashboard-header {
  background: #F7F7FC;
  color: #000;
}
.dashboard-content {
  background: #F7F7FC;
  padding: 50px;
  margin: 0;
  height: 100%;
  overflow: scroll;
}
.websites-dropdown {
  display: none;
}
@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px;
  }
}